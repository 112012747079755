body {
  background: #f4f6f8;
}

input {
  outline: none;
  width: 100%;
}

/* Search Form */
.hola-agency-search {
  font-family: "Poppins", sans-serif;
  width: fit-content;
}

/* Tabs Controls*/
.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls {
  overflow: visible;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button {
  padding: 15px 23px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;

}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button::before {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 9px #536DFE;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  border-left: solid 9px transparent;
  border-right: solid 9px transparent;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button svg path {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button span {
  margin-left: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  letter-spacing: 0.14px;
  color: #BBC6D1;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button.is-tab-el-active {
  background-color: #536DFE;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(83, 109, 254, 0.48);
  -moz-box-shadow: 0px 0px 10px 0px rgba(83, 109, 254, 0.48);
  box-shadow: 0px 0px 10px 0px rgba(83, 109, 254, 0.48);
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button.is-tab-el-active::before {
  opacity: 1;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button.is-tab-el-active span {
  color: #fff;
}

.hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button.is-tab-el-active svg path {
  fill: #fff;
}



/* Tabs Content */
.hola-agency-search .hola-agency-tabs__content {
  margin-top: 30px;
}

.hola-agency-search .hola-agency-tabs__content .hola-agency-tab__item {
  position: absolute;
  top: 0;
  visibility: hidden;
  transition: none;
  opacity: 0;
}

.hola-agency-search .hola-agency-tabs__content .hola-agency-tab__item.is-tab-el-active {
  transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch {
  border-radius: 5px;
  padding-right: 15px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid {
  grid-template-columns: 1fr 275px 320px auto;
  display: grid;
  align-items: center;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-button__item .hola-agency-search__button {
  border-radius: 5px;
  background-color: #FCB151;
  text-transform: uppercase;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  letter-spacing: 0.16px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  height: 60px;


}



.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__loc,
.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__date,
.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__guests {
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 30px;
  position: relative;
  padding-right: 30px;
}


.searchMenu-loc__field.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__date,
.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__guests {
  border-left: 1px solid #DDE4EB;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item .label {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 13px;

}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item input::placeholder,
.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item input,
.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item .placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.14px;
  color: #758CA1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item .placeholder {
  white-space: nowrap;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item .placeholder svg {
  margin-left: 10px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .hola-agency-search__item .placeholder span {
  display: contents;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .label svg {
  width: 14px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid .label span {
  margin-left: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #BBC6D1;
  font-weight: 400;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown {
  margin-top: 10px;
  -webkit-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 20;
  width: 500px;
  max-width: 100vw;
  transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  opacity: 0;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown.-is-active {
  pointer-events: auto;
  opacity: 1;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper {
  border-radius: 5px;
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row:not(:last-child) {
  margin-bottom: 25px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row>div {
  padding-left: 15px;
  padding-right: 15px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row .hola-agency-search__menu__dropdown__guests__buttons>div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row .hola-agency-search__menu__dropdown__guests__buttons button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row .hola-agency-search__menu__dropdown__guests__buttons .hola-agency-search__menu__dropdown__counter__wrapper {
    width: 40px;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests-row .hola-agency-search__menu__dropdown__guests__buttons .hola-agency-search__menu__dropdown__counter {
  margin-left: 15px;
  margin-right: 15px;

}



.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__label {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;

}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__label span {
  margin-left: 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #758CA1;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__guests__buttons button {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #EDF0F1;
  color: #9DB0C1;
}

.hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__menu__dropdown .hola-agency-search__menu__dropdown__wrapper .hola-agency-search__menu__dropdown__counter {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #536DFE;
}

.hola-agency-search .hola-agency-tabs__content .hola-agency-search-menu__guests .hola-agency-search__menu__dropdown {
  width: 300px;
}

/* Calendar */
.daterangepicker {
  border: none !important;
  margin-top: 35px !important;
  font-family: "Poppins", sans-serif !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
}

.daterangepicker:before {
  border-bottom: none !important;
}

.daterangepicker .drp-calendar {
  max-width: none !important;
}

.daterangepicker .calendar-table table thead tr:first-child th {

  padding-top: 19px;
  font-size: 14px;
  padding-bottom: 20px;
  color: #4d677e;
  font-weight: 400;
  line-height: 21px;

}


.daterangepicker .calendar-table table thead tr:last-child th {

  border-top: 1px solid #e7ebef;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  font-weight: 400;
  color: #bbc6d1 !important;
  padding-bottom: 8px;
  padding-top: 16px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 20px 20px !important;
}

.daterangepicker .drp-calendar.right {
  padding: 8px 20px 20px 0 !important;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #a6b0bb !important;
}

.daterangepicker .calendar-table .next:hover span,
.daterangepicker .calendar-table .prev:hover span {
  border-color: #536dfe !important;
}

.daterangepicker .calendar-table table thead tr:last-child th::before {
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  top: 0;
  position: absolute;
  background-color: #e7ebef;
}

.daterangepicker .calendar-table table thead tr:first-child th:hover {
  background: transparent;
}

.daterangepicker .calendar-table table thead tr:first-child th:first-child {
  padding-left: 33px;
}

.daterangepicker .calendar-table table thead tr:first-child th:last-child {
  padding-right: 33px;
}

.daterangepicker .calendar-table table tbody td {
  width: 45px;
  height: 40px;
  min-width: 45px;
  font-size: 14px;
  font-weight: 400;
  max-width: 45px;
  color: #4d677e !important;
  line-height: 24px;
}

.daterangepicker .calendar-table table tbody td.active,
.daterangepicker .calendar-table table tbody td.active:hover,
.daterangepicker .calendar-table table tbody td.active.end-date {
  background-color: #536dfe !important;
}

.daterangepicker td.active.end-date,
.daterangepicker td.active.start-date {
  color: #fff !important;
}

.daterangepicker td.in-range {

  background-color: #f0f5ff !important;
}

.daterangepicker .calendar-table table tbody td.disabled,
.daterangepicker .calendar-table table tbody option.disabled,
.daterangepicker .calendar-table table tbody td.off {
  color: #bbc6d1 !important;
}

.daterangepicker .calendar-table table tbody td:hover {
  background-color: #f5f5f5 !important;
}

@media (max-width: 1199px) {

    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__loc,
    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__date,
    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__guests {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid {
      grid-template-columns: 1fr 255px 320px auto;
    }
  }
  
  @media (max-width: 991px) {
    .hola-agency-search {
      width: 100%;
    }
  
    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search__grid {
      grid-template-columns: 1fr;
    }
  
    .hola-agency-search .hola-agency-tabs__content .mainSearch {
      padding-right: 0;
    }
  
    .hola-agency-search .hola-agency-tabs__content .hola-agency-search__grid .hola-agency-button__item {
      padding: 0 20px 20px 20px;
    }
  
    .hola-agency-search .hola-agency-tabs__content .hola-agency-search__grid .hola-agency-button__item button {
      width: 100%;
    }
  
    .hola-agency-search .hola-agency-tabs__content .mainSearch .hola-agency-search-menu__guests .hola-agency-search__item .placeholder svg {
      margin-left: auto;
  
    }
  }
  
  @media (max-width: 767px) {
    .daterangepicker .calendar-table table tbody td {
      width: 35px;
      height: 30px;
      min-width: 35px;
      max-width: 35px;
    }
  }
  
  @media (max-width: 575px) {
    .daterangepicker .calendar-table table tbody td {
      width: 30px;
      height: 25px;
      min-width: 30px;
      max-width: 30px;
    }
  
    .daterangepicker .drp-calendar.left {
      padding: 8px 10px 10px 10px !important;
    }
  
    .daterangepicker .drp-calendar.right {
      padding: 8px 10px 10px 10px !important;
    }
  
    .daterangepicker .calendar-table table thead tr:first-child th {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  
  }
  
  @media (max-width: 399px) {
    .hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button {
      padding: 10px;
    }
  
    .hola-agency-search .hola-agency-search__tabs .hola-agency-search__tabs__controls button span {
      margin-left: 8px;
    }
}
